import React from "react";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Newsletter from "components/Newsletter";

function NewsletterSection(props) {
  return (
    <section
      className="SectionComponent hero section is-block is-relative py-8"
      style={{ background: "#0078FF" }}
      id="earlybird"
    >
      <div
        className="container has-text-centered"
        style={{ paddingTop: "0rem", paddingBottom: "0rem" }}
      >
        <div
          className="is-vcentered is-centered is-variable is-8 is-multiline py-3"
          style={{ maxWidth: "514px", marginLeft: "auto", marginRight: "auto" }}
        >
          <h1
            className="h1news"
            style={{
              color: "#FFF",
              marginBottom: "1rem",
              fontSize: "2rem",
              fontWeight: "600",
            }}
          >
            Get latest updates
          </h1>
          <p
            className="is-spaced"
            style={{
              color: "#CDE4FF",
              marginBottom: "2.5rem",
              fontSize: "20px",
              fontWeight: "400",
            }}
          >
            Receive product updates about tabExtend to your inbox. Nothing more,
            nothing less. unsubscribe anytime.
          </p>
          <Newsletter
            parentColor={props.color}
            buttonText={props.buttonText}
            inputPlaceholder={props.inputPlaceholder}
            subscribedMessage={props.subscribedMessage}
            size="medium"
          ></Newsletter>
        </div>
      </div>
    </section>
  );
}

export default NewsletterSection;
