import React, { useState } from 'react';
import SectionButton from 'components/SectionButton';
import newsletter from 'util/newsletter.js';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';

function Newsletter(props) {
    const [subscribed, setSubscribed] = useState(false);
    const {
        handleSubmit,
        register,
        formState: { errors }
    } = useForm();

    const onSubmit = ({ email }) => {
        setSubscribed(true);
        // Parent component can optionally
        // find out when subscribed.
        props.onSubscribed && props.onSubscribed();
        // Subscribe them
        newsletter.subscribe({ email });
    };

    return (
        <>
            {subscribed === false && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="field is-grouped">
                        <div
                            className="control is-expanded"
                            style={{ marginRight: '12px' }}
                        >
                            <input
                                className={`input is-${props.size}`}
                                name="email"
                                type="email"
                                placeholder={'Your Email'}
                                style={{ height: '2.8em', borderRadius: '6px' }}
                                ref={register({
                                    required: 'Please enter an email address'
                                })}
                            ></input>
                        </div>
                        <div className="control">
                            <motion.button
                                whileHover={{
                                    scale: 0.975,
                                    boxShadow:
                                        '2px 4px 24px 0 rgba(4,53,108,0.6), -2px -2px 24px 0 rgba(144,187,247,0.15), inset 2px 2px 6px 0 rgba(180,214,253,0.20), inset -3px -3px 6px 0 rgba(3,96,200,0.15)'
                                }}
                                whileTap={{
                                    scale: 0.96,
                                    boxShadow:
                                        '0px 0px 0px 0 rgba(4,53,108,0.0), 0px 0px 0px 0 rgba(144,187,247,0.0), inset 3px 3px 6px 0 rgba(3,96,200,0.35), inset -4px -4px 6px 0 rgba(180,214,253,0.20)'
                                }}
                                style={{
                                    cursor: 'pointer',
                                    outline: 'none',
                                    color: '#FFF',
                                    fontWeight: '600',
                                    border: 'none',
                                    backgroundColor: '#0078FF',
                                    borderRadius: '7px',
                                    boxShadow:
                                        '4px 6px 24px 0 rgba(4,53,108,0.6), -4px -4px 24px 0 rgba(144,187,247,0.15), inset 3px 3px 6px 0 rgba(180,214,253,0.20), inset -4px -4px 6px 0 rgba(3,96,200,0.15)',
                                    minHeight: '2.8em',
                                    fontSize: '20px',
                                    minWidth: '160px'
                                }}
                            >
                                Notify me!
                            </motion.button>
                        </div>
                    </div>

                    {errors.email && (
                        <p className="help is-danger has-text-left">
                            {errors.email.message}
                        </p>
                    )}
                </form>
            )}

            {subscribed === true && (
                <div
                    style={{
                        color: '#FFF',
                        fontSize: '16px',
                        fontWeight: '500'
                    }}
                >
                    Check your email for a confirmation step. Thank you!
                </div>
            )}
        </>
    );
}

export default Newsletter;
