import React from "react";
import Section from "components/Section";
import Features2 from "components/Features2";

function FeaturesSection2(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <h2
          style={{
            fontSize: "2rem",
            fontWeight: "600",
            textAlign: "left",
            marginBottom: "2rem",
            paddingLeft: "3rem",
            paddingRight: "3rem",
          }}
        >
          Features included
        </h2>
        <Features2
          items={[
            {
              title: "Manage tabs",
              subtitle:
                "Save, Close and open groups of tabs, drag and drop or right click any page to save.",
              //icon: "/features/categories_icon.svg"
              icon: "MouseClick",
            },
            {
              title: "Notes / To-dos",
              subtitle: "Quickly write notes, add colors and turn in to to-do items.",
              //icon: "/features/text_snippets_icon.svg",
              icon: "AddNote",
            },
            {
              title: "Reminders",
              subtitle: "Setup and get reminded about sites, notes and to-do's.",
              //icon: "/features/share_icon.svg",
              icon: "AlarmIcon",
            },
            {
              title: "Search",
              subtitle: "Easily find what you are looking for with the search modal.",
              //icon: "/features/focus_icon.svg",
              icon: "SearchIcon",
            },
            {
              title: "Bookmarks and top sites",
              subtitle: "Generate groups populated with your bookmarks and most visited sites.",
              //icon: "/features/html_icon.svg",
              icon: "GlobeIcon",
            },
            {
              title: "Themes",
              subtitle: "Dark-mode, light-mode and auto-dark/light-mode.",
              //icon: "/features/theme_icon.svg",
              icon: "SettingsIcon",
            },
            {
              title: "Share",
              subtitle: "Share workspaces/categories privately or publicly with others.",
              //icon: "/features/focus_icon.svg",
              icon: "ShareCloud",
            },
            {
              title: "Site popover",
              subtitle:
                "In-site popover with options for saving and accessing tabs + creating notes.",
              //icon: "/features/theme_icon.svg",
              icon: "CopyClipboard",
            },
            {
              title: "Mobile apps",
              subtitle: "iOS and Android apps to access and save notes and sites.",
              //icon: "/features/html_icon.svg",
              icon: "MobileIcon",
            },
          ]}
        ></Features2>
      </div>
    </Section>
  );
}

export default FeaturesSection2;
