import React from "react";

function SectionHeader(props) {
  // Render nothing if no title or subtitle
  if (!props.title && !props.subtitle) {
    return null;
  }

  return (
    <header
      className={
        "SectionHeader" + (props.className ? ` ${props.className}` : "")
      }
    >
      {props.limited && (
        <div
          className="tag is-primary is-medium"
          style={{
            backgroundColor: "#FED844",
            color: "#6D4108",
            fontWeight: "600",
            marginBottom: "2rem",
          }}
        >
          For a limited time
        </div>
      )}
      {props.title && (
        <h1
          className={
            "title has-text-weight-bold" +
            (props.size ? ` is-${props.size}` : "") +
            (props.size === 1 ? " is-size-2-mobile" : "") +
            (props.spaced ? " is-spaced" : "")
          }
        >
          {props.title}
        </h1>
      )}

      {props.subtitle && (
        <p
          className={"subtitle" + (props.size > 4 ? " is-6" : "")}
          style={{
            fontFamily: "Rubik, sans-serif",
            color: "#687482",
            fontSize: "1.225rem",
            lineHeight: "1.46",
            letterSpacing: "0.48px",
          }}
        >
          <span
            className="SectionHeader__no-classname"
            style={{ maxWidth: "625px" }}
          >
            {props.subtitle}
          </span>
        </p>
      )}
    </header>
  );
}

export default SectionHeader;
