import React from "react";

function HeroText(props) {
  // Render nothing if no title or subtitle
  if (!props.title && !props.subtitle) {
    return null;
  }

  return (
    <header
      className={
        "SectionHeader" + (props.className ? ` ${props.className}` : "")
      }
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {props.title && (
        <h1
          className={
            "title has-text-weight-bold" +
            (props.size ? ` is-${props.size}` : "") +
            (props.size === 1 ? " is-size-2-mobile heroLine" : "") +
            (props.spaced ? " is-spaced" : "")
          }
          style={{
            fontSize: props.size === 1 ? "4.225rem" : "",
            fontWeight: props.size === 1 ? "800 !important" : "700",
          }}
        >
          {props.title}
        </h1>
      )}

      {props.subtitle && (
        <p
          className={"subtitle" + (props.size > 4 ? " is-6" : "")}
          style={{
            fontFamily: "Rubik, sans-serif",
            color: "#687482",
            fontSize: props.size === 1 ? "1.375rem" : "1.225rem",
            lineHeight: props.size === 1 ? "1.36" : "1.46",
            letterSpacing: "0.48px",
          }}
        >
          <span className="SectionHeader__no-classname">{props.subtitle}</span>
        </p>
      )}
    </header>
  );
}

export default HeroText;
