import React, { useState } from 'react';
import SectionButton from 'components/SectionButton';
import onetimeEmail from 'util/onetimeEmail.js';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { usePlausible } from 'next-plausible';

function OnetimeEmailForm() {
    const plausible = usePlausible();
    const [emailSent, setEmailSent] = useState(false);
    const {
        handleSubmit,
        register,
        formState: { errors }
    } = useForm();

    const onSubmit = ({ email, name }) => {
        if (name.length > 1) {
            console.log('Oh, you spam');
            return;
        } else {
            setEmailSent(true);
            plausible('userSentReminderEmail');
            // Send Email
            onetimeEmail.sendOneEmail({ email });
        }
    };

    let ohnohoneypot = {
        opacity: '0',
        position: 'absolute',
        top: '0',
        left: '0',
        height: '0',
        width: '0',
        zIndex: '-1'
    };

    return (
        <>
            {emailSent === false && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="field is-grouped">
                        <div
                            className="control is-expanded"
                            style={{ marginRight: '12px' }}
                        >
                            <input
                                className={`input`}
                                name="email"
                                type="email"
                                placeholder={'Your Email'}
                                style={{
                                    height: '2.8em',
                                    borderRadius: '6px'
                                }}
                                {...register('email', {
                                    required: 'Please enter an email address'
                                })}
                            ></input>
                            <label style={ohnohoneypot} for="name"></label>
                            <input
                                style={ohnohoneypot}
                                autocomplete="off"
                                type="text"
                                id="name"
                                placeholder="Your name here"
                                name="name"
                                {...register('name', {})}
                            ></input>
                        </div>
                        <div className="control">
                            <motion.button
                                whileHover={{
                                    scale: 0.975,
                                    boxShadow:
                                        '2px 4px 24px 0 rgba(4,53,108,0.6), -2px -2px 24px 0 rgba(144,187,247,0.15), inset 2px 2px 6px 0 rgba(180,214,253,0.20), inset -3px -3px 6px 0 rgba(3,96,200,0.15)'
                                }}
                                whileTap={{
                                    scale: 0.96,
                                    boxShadow:
                                        '0px 0px 0px 0 rgba(4,53,108,0.0), 0px 0px 0px 0 rgba(144,187,247,0.0), inset 3px 3px 6px 0 rgba(3,96,200,0.35), inset -4px -4px 6px 0 rgba(180,214,253,0.20)'
                                }}
                                style={{
                                    cursor: 'pointer',
                                    outline: 'none',
                                    color: '#FFF',
                                    fontWeight: '600',
                                    border: 'none',
                                    backgroundColor: '#0078FF',
                                    borderRadius: '7px',
                                    boxShadow:
                                        '4px 6px 24px 0 rgba(4,53,108,0.6), -4px -4px 24px 0 rgba(144,187,247,0.15), inset 3px 3px 6px 0 rgba(180,214,253,0.20), inset -4px -4px 6px 0 rgba(3,96,200,0.15)',
                                    minHeight: '2.8em',
                                    fontSize: '16px',
                                    minWidth: '160px'
                                }}
                            >
                                Send a reminder
                            </motion.button>
                        </div>
                    </div>

                    {errors?.email && (
                        <p className="help is-danger has-text-left">
                            {errors.email.message}
                        </p>
                    )}
                </form>
            )}

            {emailSent === true && (
                <div
                    style={{
                        color: '#FFF',
                        fontSize: '16px',
                        fontWeight: '700'
                    }}
                >
                    A friendly reminder about tabExtend was sent to your
                    inbox(please check your promotions inbox), cheers!
                </div>
            )}
        </>
    );
}

export default OnetimeEmailForm;
