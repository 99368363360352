import React from "react";
import Image from "next/image";

function Clients(props) {
  return (
    <div className="columns is-centered is-multiline">
      {props.items.map((item, index) => (
        <div className="column is-narrow has-text-centered" key={index}>
          <div
            className="Clients__logo"
            style={{
              maxWidth: "100%",
              width: "148px",
              paddingLeft: "24px",
              margin: "auto",
              marginTop: "12px",
            }}
          >
            <Image
              src={item.image}
              width={item.width}
              height={item.width}
              alt={item.name}
            ></Image>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Clients;
