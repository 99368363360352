import React from "react";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Features from "components/Features";
import {
  motion,
  useViewportScroll,
  useTransform,
  useSpring,
} from "framer-motion";

function FeaturesSection(props) {
  const { scrollYProgress } = useViewportScroll();
  //let bgMoveY = useTransform(scrollYProgress, value => -value * 600);
  //let bgMoveY28 = useTransform(scrollYProgress, [0, 1], [400, -450]);

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container" style={{ paddingBottom: "6rem" }}>
        {/*         <motion.div style={{y: bgMoveY20, background: "url(/features/bg_circle.svg)", backgroundRepeat: "no-repeat",backgroundPosition: "center", backgroundSize: "cover", height: "20rem", width: "20rem", position: "absolute", left: "80%", top: "-7%"}} />
        <motion.div style={{y: bgMoveY28, background: "url(/features/bg_circle.svg)", backgroundRepeat: "no-repeat",backgroundPosition: "center", backgroundSize: "cover", height: "24rem", width: "24rem", position: "absolute", left: "-15%", top: "18%"}} />
        <motion.div style={{y: bgMoveY18, background: "url(/features/bg_circle.svg)", backgroundRepeat: "no-repeat",backgroundPosition: "center", backgroundSize: "cover", height: "18rem", width: "18rem", position: "absolute", left: "86%", top: "43%"}} />
        <motion.div style={{y: bgMoveY16, background: "url(/features/bg_circle.svg)", backgroundRepeat: "no-repeat",backgroundPosition: "center", backgroundSize: "cover", height: "16rem", width: "16rem", position: "absolute", left: "-4%", top: "74%"}} /> */}
        <Features
          items={[
            {
              title: "A powerful new tab",
              description:
                "Quickly declutter your workspace by dragging and dropping your tabs into groups. Easily add notes and to-dos, save text-snippets from websites, share and collaborate, all in your browser.",
              image: "/features/first_drop.gif",
              circleBg: "-webkit-radial-gradient(top right, #FDEB71, #F99216);",
              icon: "/features/home_icon.png",
              iconname: "homescreen",
            },
            {
              title: "Easily save, close and open groups of tabs",
              description:
                "Content switch efficiently without worry. Save or close all tabs in the window at once. One-click-tap to open another set of tabs.",
              image: "/features/active_tabs.png",
              circleBg: "-webkit-radial-gradient(top right, #FF6DAB, #7366FF);",
              icon: "/features/save_icon.png",
              iconname: "tabs",
            },
            {
              title: "A clear overview",
              description:
                "Minimize cognitive overload, reduce the time to find what you are looking for and don't get distracted on your way there. By dropping tabs that are not used your computer can also catch a break reducing up to 95% of its memory usage.",
              image: "/features/space_untitled.png",
              circleBg:
                "-webkit-radial-gradient(top right, #10EC00, #0ACAC5, #1728BB);",
              icon: "/features/clear_icon.png",
              iconname: "focus",
            },
          ]}
        ></Features>
      </div>
    </Section>
  );
}

export default FeaturesSection;
