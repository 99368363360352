import React from 'react';
import { motion } from 'framer-motion';

function SectionButton(props) {
    const {
        parentColor,
        size,
        state,
        fullWidth,
        rightAligned,
        // Passed to button element
        ...otherProps
    } = props;

    return (
        <motion.button
            whileHover={{ opacity: 0.9 }}
            whileTap={{ scale: 0.9875 }}
            transition={{ type: 'spring', duration: 0.15 }}
            className={
                'button' +
                ([
                    'primary',
                    'info',
                    'success',
                    'warning',
                    'danger',
                    'black',
                    'dark'
                ].includes(parentColor)
                    ? ` is-${parentColor} is-inverted`
                    : '') +
                (['white', 'light'].includes(parentColor) || !parentColor
                    ? ' is-primary'
                    : '') +
                (size ? ` is-${size}` : '') +
                (state ? ` is-${state}` : '') +
                (fullWidth ? ' is-fullwidth' : '')
            }
            style={{
                opacity: '1',
                backgroundImage:
                    'linear-gradient(-45deg, #3493FF 0%, #5CABFE 100%)',
                borderRadius: '8px',
                boxShadow: '0 8px 24px -8px rgba(0,120,255,0.50)',
                fontWeight: '600',
                fontSize: '18px',
                minHeight: '32px',
                height: '44px',
                //letterSpacing: '0.54px',
                marginLeft: rightAligned ? 'auto' : '',
                marginTop: rightAligned ? '72px' : '16px'
            }}
            {...otherProps}
        >
            {props.children}
        </motion.button>
    );
}

export default SectionButton;
