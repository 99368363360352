import React, { useState } from 'react';
import Section from 'components/Section';
import Features2 from 'components/Features2';
import Image from 'next/image';
import { motion } from 'framer-motion';

const Bubble = ({ text, name, title, imgUrl, index, hovered, setHovered }) => {
    let selected = hovered === index ? true : false;
    return (
        <motion.div
            animate={{
                scale: hovered === index ? 1.0375 : 1,
                y: hovered === index ? -2 : 0,
                filter:
                    hovered !== index && hovered !== null ? 'blur(2px)' : '',
                opacity: hovered !== index && hovered !== null ? '0.9' : '1'
            }}
            onHoverStart={(e) => setHovered(index)}
            onHoverEnd={(e) => setHovered(null)}
            whileTap={{ scale: 1 }}
            transition={{
                duration: 0.1,
                bounce: 0.1,
                damping: 6,
                //delay: 0.3,
                type: 'spring'
                //stiffness: 100,
                //bounce: 0.5,
            }}
            className="column is-one-fourth"
            style={{
                cursor: 'pointer',
                minWidth: '240px',
                margin: '1.5rem',
                minHeight: '396px',
                background: '#FEFEFE',
                boxShadow:
                    '0 52px 61px -25px rgba(37,42,47,0.50), inset 0 0 6px 0 #E5EAF3, inset -2px -2px 1px 0 #D5DAE5, inset 2px 2px 2px 0 #FFFFFF',
                borderRadius: '16px',
                padding: '3rem'
            }}
        >
            <div
                style={{
                    height: '194px',
                    alignItems: 'center',
                    display: 'flex',
                    marginTop: '0.5rem'
                }}
            >
                <h4 style={{ fontSize: '1rem', fontWeight: '500' }}>{text}</h4>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '3rem'
                }}
            >
                <Image
                    src={imgUrl}
                    width={56}
                    height={56}
                    alt="founder picture"
                    style={{ borderRadius: '50%' }}
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '24px'
                    }}
                >
                    <h3
                        style={{
                            fontFamily: 'Rubik, sans-serif',
                            color: '#363636',
                            fontSize: '1.075rem',
                            fontWeight: '600',
                            lineHeight: '1.525'
                        }}
                    >
                        {name}
                    </h3>
                    <p
                        style={{
                            fontFamily: 'Rubik, sans-serif',
                            color: '#646F7D',
                            fontSize: '0.85rem',
                            fontWeight: '400',
                            lineHeight: '1.65'
                        }}
                    >
                        {title}
                    </p>
                </div>
            </div>
        </motion.div>
    );
};

function Testamonials(props) {
    const [hovered, setHovered] = useState(null);

    return (
        <Section
            color={props.color}
            size={props.size}
            backgroundImage={props.backgroundImage}
            backgroundImageOpacity={props.backgroundImageOpacity}
            style={{
                background: '#0078FF',
                marginRight: '-1rem',
                marginLeft: '-1rem'
            }}
        >
            <div className="container">
                <div
                    className="columns is-centered"
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        marginBottom: '4rem',
                        marginTop: '4rem'
                    }}
                >
                    <Bubble
                        text={
                            '“I have tried every extension out there (including OneTab, Session Buddy, Great Suspender), but I can honestly say that tabExtend is the one!”'
                        }
                        name={'Shawn Park'}
                        title={'Be Your Own Nerd Podcast'}
                        imgUrl={'/shawn_f.png'}
                        index={1}
                        hovered={hovered}
                        setHovered={setHovered}
                    />
                    <Bubble
                        text={
                            '“This is a must have extension for anyone that tends to have a lot of tabs open. Love it!”'
                        }
                        name={'Gabe Ragland'}
                        title={'Founder, Divjoy'}
                        imgUrl={'/gabe_r.png'}
                        index={2}
                        hovered={hovered}
                        setHovered={setHovered}
                    />
                    <Bubble
                        text={
                            '“…I use a lot of resources when trying to meet client needs, being able to save references, research and even notes for later viewing and consumption in one place is VERY VERY convenient!”'
                        }
                        name={'Vincent Bekong'}
                        title={'Freelance Artist'}
                        imgUrl={'/vincent_b.png'}
                        index={3}
                        hovered={hovered}
                        setHovered={setHovered}
                    />
                </div>
            </div>
        </Section>
    );
}

export default Testamonials;
