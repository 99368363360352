import React, { useEffect, useRef, useState } from 'react';
import Section from 'components/Section';
import {
    motion,
    useAnimation,
    useElementScroll,
    useTransform,
    useViewportScroll
} from 'framer-motion';
import Link from 'next/link';
import { SecureIcon, ArrowBubble } from 'components/IconBlocks';
import { useInView, InView } from 'react-intersection-observer';

const BlackItem = ({ inView }) => {
    return (
        <motion.div
            animate={{
                scale: inView ? 1 : 0
            }}
            transition={{
                duration: 0.4,
                delay: 0.3,
                type: 'spring',
                stiffness: 100,
                bounce: 0.5
            }}
            style={{
                height: '54px',
                width: '54px',
                background: '#F4F5F7',
                backgroundImage:
                    'linear-gradient(134deg, #33373A 0%, #1B1E21 100%)',
                boxShadow:
                    'inset 1px 1px 4px 0 rgba(185,193,198,0.25), inset -1px -1px 4px 0 rgba(3,3,4,0.90)',
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1.8rem'
            }}
        >
            <motion.div
                animate={{ scale: inView ? 1 : 0 }}
                transition={{
                    duration: 0.4,
                    delay: 0.32
                }}
                style={{ margin: 'auto', display: 'flex' }}
            >
                <SecureIcon IconColor={'#FFF'} height={'40px'} width={'36px'} />
            </motion.div>
        </motion.div>
    );
};

function FullWidthArea(props) {
    const [hoverLink, setHoverLink] = useState(false);
    //const ref = useRef()
    const { scrollYProgress } = useViewportScroll();
    let card1X = useTransform(scrollYProgress, [0, 1], [5, 155]);
    let card2X = useTransform(scrollYProgress, [0, 1], [-25, -75]);
    let card3X = useTransform(scrollYProgress, [0, 1], [-45, -305]);
    let card2scale = useTransform(scrollYProgress, [0, 1], [-80, -160]);
    let card3scale = useTransform(scrollYProgress, [0, 1], [-120, -300]);
    let card1rotate = useTransform(scrollYProgress, [0, 1], [-45, -5]);

    return (
        <div className="SectionComponent hero is-relative p-0">
            <div
                className="hero-body is-flex-tablet p-0 is-vcentered has-text-centered-mobile"
                style={{ padding: '0px' }}
            >
                <div
                    className="tile"
                    style={{
                        background: '#15171B',
                        minHeight: '740px',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            paddingTop: '15px',
                            position: 'relative',
                            margin: 'auto',
                            width: '400px',
                            height: '500px',
                            perspective: '800px'
                        }}
                    >
                        <motion.div
                            style={{
                                width: '400px',
                                position: 'absolute',
                                x: card3X,
                                z: card3scale /* rotateY: card1rotate */
                            }}
                        >
                            <img
                                src={'/features/space_insp.png'}
                                loading="lazy"
                                alt="Inspiration container"
                            />
                        </motion.div>
                        <motion.div
                            style={{
                                width: '400px',
                                position: 'absolute',
                                x: card2X,
                                z: card2scale /* rotateY: card1rotate */
                            }}
                        >
                            <img
                                src={'/features/space_notes.png'}
                                loading="lazy"
                                alt="Notes container"
                            />
                        </motion.div>
                        <motion.div
                            style={{
                                width: '400px',
                                position: 'absolute',
                                x: card1X /* rotateY: card1rotate */
                            }}
                        >
                            <img
                                src={'/features/space_trainreads.png'}
                                loading="lazy"
                                alt="Read on train container"
                            />
                        </motion.div>
                    </div>
                </div>
                <div
                    className="tile"
                    style={{
                        backgroundImage:
                            'linear-gradient(180deg, #262A2D 0%, #262A2D 100%)'
                    }}
                >
                    <div
                        className="column is-half is-8"
                        style={{
                            margin: 'auto',
                            padding: '2rem 2rem 4rem 2rem',
                            textAlign: 'left'
                        }}
                    >
                        <InView triggerOnce>
                            {({ inView, ref }) => (
                                <div
                                    ref={ref}
                                    className="is-inline-block-tablet"
                                >
                                    <BlackItem inView={inView} />
                                </div>
                            )}
                        </InView>
                        <h3
                            className="Features__title title has-text-weight-bold is-spaced is-3 isFFF"
                            style={{
                                textAlign: 'left',
                                fontSize: '2rem'
                            }}
                        >
                            Privacy first
                        </h3>
                        <p
                            className="subtitle"
                            style={{
                                fontFamily: 'Rubik, sans-serif',
                                color: '#C4CCD6',
                                fontSize: '1.175rem',
                                lineHeight: '1.5',
                                textAlign: 'left'
                            }}
                        >
                            To tap into some of the browser's capabilities, like
                            listing all active tabs in the window, we request
                            some permissions upon installation. We take your
                            privacy dead serious so we make sure to never share
                            your data with third parties or use any tracking
                            software.
                        </p>
                        <motion.div
                            onHoverStart={(e) => {
                                setHoverLink(true);
                            }}
                            onHoverEnd={(e) => {
                                setHoverLink(false);
                            }}
                            style={{
                                position: 'relative',
                                display: 'inline-block'
                            }}
                        >
                            {hoverLink && (
                                <motion.div
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1 }}
                                    style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        position: 'absolute',
                                        left: '0',
                                        top: '2px'
                                    }}
                                    //transition={{ type: "spring", duration: "0.1s" }}
                                >
                                    <ArrowBubble
                                        IconColor={'#FFF'}
                                        height={'24px'}
                                        width={'24px'}
                                    />
                                </motion.div>
                            )}
                            <motion.div
                                animate={{
                                    x: hoverLink ? 32 : 0
                                    //fontWeight: hoverLink ? "500" : "400",
                                }}
                            >
                                <Link
                                    href="/guide"
                                    style={{
                                        color: hoverLink ? '#FFF' : '#C4CCD6',
                                        fontFamily: 'Rubik, sans-serif',
                                        fontSize: '1.175rem'
                                    }}
                                >
                                    Read more about the permissions
                                </Link>
                            </motion.div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
        /*
      <div className="hero-body is-flex-tablet p-0 is-vcentered" style={{padding: "0px"}}>
            <div className="column is-gapless p-0">
            <div style={{minHeight: "600px", paddingTop: "15px", display: "flex", alignItems: "center" }}>
              <motion.div style={{width: "400px", position: "absolute"}}>
              <img src={"/features/space_insp.png"}/>
              </motion.div>
              <motion.div style={{width: "400px", position: "absolute"}}>
              <img src={"/features/space_notes.png"}/>
              </motion.div>
              <motion.div style={{width: "400px", position: "absolute"}}>
              <img src={"/features/space_trainreads.png"}/>
              </motion.div>
              </div>
            </div>
            <div className="column is-gapless p-0" style={{border: "4px solid green", minHeight: "600px",backgroundImage: "linear-gradient(180deg, #262A2D 0%, #262A2D 100%)", height: "100%", width: "250px", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px"}}></div>*/
    );
}

export default FullWidthArea;
