import React, { useState, useEffect } from "react";
import {
  motion,
  useViewportScroll,
  useTransform,
  useSpring,
} from "framer-motion";

function HeroSpace({ showCard }) {
  //Height of page (commented out)
  //const [pageHeight, setPageHeight] = useState();
  const [onLoad, setOnload] = useState(true);
  useEffect(() => {
    //let val = document.body.scrollHeight
    //setPageHeight(val)
    setOnload(false);
  }, []);

  //let scrollCard = pageHeight / 500

  const { scrollYProgress } = useViewportScroll();
  //Card
  let cardPosY = useTransform(scrollYProgress, [0, 0.12, 1], [0, 450, 450]);

  //Item 1
  const item1Range = 0.08;
  let itemScrollZ = useTransform(
    scrollYProgress,
    [0, item1Range, 1],
    [80, 0, 0]
  );
  let itemScrollY = useTransform(
    scrollYProgress,
    [0, item1Range, 1],
    [-25, 0, 0]
  );
  let itemScrollX = useTransform(
    scrollYProgress,
    [0, item1Range, 1],
    [-30, 0, 0]
  );
  let itemScrollO = useTransform(
    scrollYProgress,
    [0, item1Range, 1],
    [1, 0, 0]
  );
  let itemShadow = useTransform(
    scrollYProgress,
    [0, item1Range, 1],
    [
      "drop-shadow(0 2px 6px rgba(152,169,185,0.50))",
      "drop-shadow(0 2px 6px rgba(152,169,185,0.0))",
      "drop-shadow(0 2px 6px rgba(152,169,185,0.0))",
    ]
  );
  let itemBorder = useTransform(
    scrollYProgress,
    [0, item1Range, 1],
    ["1px solid #41E18B", "0px solid #41E18B", "0px solid #41E18B"]
  );

  //Item 2
  const item2Range = 0.1;
  let item2ScrollZ = useTransform(
    scrollYProgress,
    [0, item2Range, 1],
    [80, 0, 0]
  );
  let item2ScrollY = useTransform(
    scrollYProgress,
    [0, item2Range, 1],
    [0, 0, 0]
  );
  let item2ScrollX = useTransform(
    scrollYProgress,
    [0, item2Range, 1],
    [30, 0, 0]
  );
  let item2ScrollO = useTransform(
    scrollYProgress,
    [0, item2Range, 1],
    [1, 0, 0]
  );
  let item2Shadow = useTransform(
    scrollYProgress,
    [0, item2Range, 1],
    [
      "drop-shadow(0 2px 6px rgba(152,169,185,0.50))",
      "drop-shadow(0 2px 6px rgba(152,169,185,0.0))",
      "drop-shadow(0 2px 6px rgba(152,169,185,0.0))",
    ]
  );
  let item2Border = useTransform(
    scrollYProgress,
    [0, item2Range, 1],
    ["1px solid #41E18B", "0px solid #41E18B", "0px solid #41E18B"]
  );

  //Item 3
  const item3Range = 0.12;
  let item3ScrollZ = useTransform(
    scrollYProgress,
    [0, item3Range, 1],
    [80, 0, 0]
  );
  let item3ScrollY = useTransform(
    scrollYProgress,
    [0, item3Range, 1],
    [10, 0, 0]
  );
  let item3ScrollX = useTransform(
    scrollYProgress,
    [0, item3Range, 1],
    [-30, 0, 0]
  );
  let item3ScrollO = useTransform(
    scrollYProgress,
    [0, item3Range, 1],
    [1, 0, 0]
  );
  let item3Shadow = useTransform(
    scrollYProgress,
    [0, item3Range, 1],
    [
      "drop-shadow(0 2px 6px rgba(152,169,185,0.50))",
      "drop-shadow(0 2px 6px rgba(152,169,185,0.0))",
      "drop-shadow(0 2px 6px rgba(152,169,185,0.0))",
    ]
  );
  let item3Border = useTransform(
    scrollYProgress,
    [0, item3Range, 1],
    ["1px solid #41E18B", "0px solid #41E18B", "0px solid #41E18B"]
  );

  // 1 column?
  //if (something) {
  //  return null;
  //}
  const finalShadow = "0 2px 30px -8px rgba(152,169,185,0)";
  /* 
  let mouseX = useSpring(0);
  let mouseY = useSpring(0);

  let cardX = useTransform(mouseX, value => -value / 30);
  let cardY = useTransform(mouseY, value => -value / 30);
  let cardRotateX = useTransform(mouseX, value => -value / 80);
  let cardRotateY = useTransform(mouseY, value => -value / 80);
  let site1X = useTransform(mouseX, value => -value / 20 - 30);
  let site1Y = useTransform(mouseY, value => -value / 20 - 10);
  let site2X = useTransform(mouseX, value => -value / 20 + 30);
  let site2Y = useTransform(mouseY, value => -value / 20);
  let site3X = useTransform(mouseX, value => -value / 20 - 30);
  let site3Y = useTransform(mouseY, value => -value / 20 + 10); */

  let cardShadow =
    "0 2px 12px -1px rgba(219,221,223,0.80), 0 32px 44px -26px rgba(148,155,162,0.90)";

  return (
    <div
      style={{
        position: "relative",
        zIndex: "2",
      }}
      /*       onMouseMove={function(event) {
        let offsetX = event.clientX - window.innerWidth / 2;
        let offsetY = event.clientY - window.innerHeight / 2;
        mouseX.set(offsetX);
        mouseY.set(offsetY);

      }} */
    >
      {showCard && (
        <motion.div
          /*         initial={{
          z: onLoad ? 90 : 0,
          scale: onLoad ? 1.075 : 1,
        }}
        animate={{
          z: 0,
          scale: [1, 1.025, 1, 1.02, 1],
          transition: { duration: 1.7, delay: 0.1, type: "spring" },
        }} */
          style={{
            y: cardPosY,
            position: "relative",
            margin: "auto",
            //height: "500px",
            paddingTop: "28px",
            width: "363px",
            perspective: "1000px",
            //x: cardX,
            //y: cardY,
            //rotateX: cardRotateY,
            //rotateY: cardRotateX,
            //transform: "rotateY(-5deg) rotateX(10deg) translateZ(0px)"
          }}
        >
          <motion.div
            layoutId="card"
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              zIndex: "1",
              //transform: "rotateY(8deg) rotateX(6deg) translateZ(0px)",
              height: "500px",
              width: "363px",
              background: "#FFF",
              borderRadius: "16px",
              boxShadow: cardShadow,
              margin: "auto",
            }}
          />
          <div
            style={{
              width: "303px",
              left: "27px",
              position: "absolute",
              zIndex: "2",
            }}
          >
            <img
              src={"/heroMock/SpaceHeader.png"}
              alt="Space Symbol and Title"
            ></img>
          </div>
          <motion.div
            /*           initial={{
            x: onLoad ? -40 : -30,
            y: onLoad ? -20 : -10,
            z: onLoad ? 160 : 80,
            border: "1px solid rgb(65, 225, 139, 0.0)",
          }}
          animate={{
            x: -30,
            y: -10,
            z: 80,
            border: "1px solid rgb(65, 225, 139, 1.0)",
            //boxShadow: finalShadow,
            transition: { duration: 1.5, delay: 0.1, type: "spring" },
          }} */
            style={{
              overflow: "hidden",
              position: "absolute",
              zIndex: "3",
              top: "104px",
              left: "13px",
              borderRadius: "7px",
              //x: site1X,
              //y: site1Y,
              z: itemScrollZ,
              y: itemScrollY,
              x: itemScrollX,
              border: itemBorder,
              filter: itemShadow,
              width: "337px",
              //transform: "rotateY(8deg) rotateX(6deg) translateZ(0px)",
              //transform: "translateX(-40px) translateZ(90px)",
            }}
          >
            <img src={"/heroMock/Wired.png"} alt="Saved site 1"></img>
          </motion.div>
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: { duration: 1.5, delay: 0, type: "spring" },
            }}
            style={{
              opacity: itemScrollO,
              position: "absolute",
              zIndex: "2",
              top: "104px",
              left: "13px",
              borderRadius: "7px",
              width: "337px",
              height: "60px",
              border: "1px solid #41E18B",
              backgroundColor: "rgb(9, 233, 1, 0.05)",
            }}
          />
          <motion.div
            /*           initial={{
            x: onLoad ? 40 : 30,
            y: 0,
            z: onLoad ? 160 : 80,
            border: onLoad ? "0px solid #41E18B" : "1px solid #41E18B",
          }}
          animate={{
            x: 30,
            y: 0,
            z: 80,
            border: "1px solid #41E18B",
            //boxShadow: finalShadow,
            transition: { duration: 1.5, delay: onLoad ? 0.6 : 0, type: "spring" },
          }} */
            style={{
              overflow: "hidden",
              borderRadius: "7px",
              position: "absolute",
              zIndex: "3",
              top: "168px",
              left: "13px",
              width: "337px",
              //x: site2X,
              //y: site2Y,
              z: item2ScrollZ,
              y: item2ScrollY,
              x: item2ScrollX,
              border: item2Border,
              filter: item2Shadow,
              //transform: "rotateY(8deg) rotateX(6deg) translateZ(0px)",
            }}
          >
            <img src={"/heroMock/TechCrunch.png"} alt="Saved site 2"></img>
          </motion.div>
          <motion.div
            /*           initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: { duration: 1.5, delay: onLoad ? 0.5 : 0 , type: "spring" },
          }} */
            style={{
              opacity: item2ScrollO,
              position: "absolute",
              zIndex: "2",
              top: "168px",
              left: "13px",
              borderRadius: "7px",
              width: "337px",
              height: "60px",
              border: "1px solid #41E18B",
              backgroundColor: "rgb(9, 233, 1, 0.05)",
            }}
          />
          <motion.div
            /*           initial={{
            x: onLoad ? -40 : -30,
            y: onLoad ? 20 : 10,
            z: onLoad ? 160 : 80,
            border: onLoad ? "0px solid #41E18B" : "1px solid #41E18B",
          }}
          animate={{
            x: -30,
            y: 10,
            z: 80,
            border: "1px solid #41E18B",
            //boxShadow: finalShadow,
            transition: { duration: 1.5, delay: onLoad ? 1.2 : 0, type: "spring" },
          }} */
            style={{
              overflow: "hidden",
              borderRadius: "7px",
              position: "absolute",
              zIndex: "3",
              top: "232px",
              left: "13px",
              width: "337px",
              //x: site3X,
              //y: site3Y,
              z: item3ScrollZ,
              y: item3ScrollY,
              x: item3ScrollX,
              border: item3Border,
              filter: item3Shadow,
              //transform: "rotateY(8deg) rotateX(6deg) translateZ(0px)",
            }}
          >
            <img src={"/heroMock/SaveMoreGoodStuff.png"} alt="Note"></img>
          </motion.div>
          <motion.div
            /*           initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: { duration: 1.5, delay: onLoad ? 1.1 : 0, type: "spring" },
          }} */
            style={{
              opacity: item3ScrollO,
              position: "absolute",
              zIndex: "2",
              top: "232px",
              left: "13px",
              borderRadius: "7px",
              width: "337px",
              height: "44px",
              border: "1px solid #41E18B",
              backgroundColor: "rgb(9, 233, 1, 0.05)",
            }}
          />
        </motion.div>
      )}
    </div>
  );
}

export default HeroSpace;
