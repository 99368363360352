import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowBubble } from 'components/IconBlocks';
import Link from 'next/link';

export function CTAButtonTemp(props) {
    const {
        parentColor,
        size,
        state,
        fullWidth,
        // Passed to button element
        ...otherProps
    } = props;

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <a href="https://chrome.google.com/webstore/detail/tabextend/ffikidnnejmibopbgbelephlpigeniph">
                <motion.button
                    whileHover={{
                        scale: 1.0425,
                        boxShadow:
                            props.type === 'blueBg'
                                ? '0 8px 30px -8px rgba(9,40,74,0.30)'
                                : '0 8px 30px -8px rgba(0,120,255,0.60)',
                        borderRadius: '16px'
                    }}
                    whileTap={{
                        scale: 1.0125,
                        borderRadius: '18px',
                        opacity: '0.8'
                    }}
                    style={{
                        backgroundColor:
                            props.type === 'blueBg' ? '#FFF' : '#0078FF',
                        borderRadius: '16px',
                        zIndex: '100',
                        transform: 'translateZ(0)',
                        boxShadow:
                            props.type === 'blueBg'
                                ? '0 16px 24px -10px rgba(9,40,74,0.30)'
                                : '0 14px 24px -10px rgba(0,120,255,0.60)',
                        letterSpacing: '0.54px',
                        color: props.type === 'blueBg' ? '#1A2C4F' : '#FFF'
                    }}
                    className={
                        'button' +
                        ([
                            'primary',
                            'info',
                            'success',
                            'warning',
                            'danger',
                            'black',
                            'dark'
                        ].includes(parentColor)
                            ? ` is-${parentColor} is-inverted`
                            : '') +
                        (['white', 'light'].includes(parentColor) ||
                        !parentColor
                            ? ' is-primary'
                            : '') +
                        (size ? ` is-${size}` : '') +
                        (state ? ` is-${state}` : '') +
                        (fullWidth ? ' is-fullwidth' : '') +
                        ' HeroCTA'
                    }
                    {...otherProps}
                >
                    <b>Try for free</b>, Go to Chrome store
                </motion.button>
            </a>
        </div>
    );
}

export const CTAbuttonSection = () => {
    const parentAnimation = {
        notHovered: {
            filter: 'brightness(1)'
            //scale: 1,
        },
        hover: {
            //scale: 1.025,
            filter: 'brightness(1.125)'
        },
        tapping: {}
    };

    const arrowAnimation = {
        notHovered: {
            x: 0,
            scale: 1,
            boxShadow: '0 0px 8px -1px rgba(255, 255, 255, 0.6)'
            //filter: 'brightness(0.985)'
        },
        hover: {
            x: 2,
            scale: 1.125,
            boxShadow: '0 0px 8px -1px rgba(255, 255, 255, 0.6)'
            //boxShadow: "0 0px 32px -1px #ffffff90",
            //filter: 'brightness(1.05)',
            //transition: { type: "spring" },
        },
        tapping: {
            scale: 1,
            x: 8,
            boxShadow: '0px 0px 16px -1px rgba(255, 255, 255, 0.1)'
        }
    };

    const textAnimation = {
        notHovered: {
            x: 0,
            opacity: 1
        },
        hover: {
            x: 4,
            opacity: 1
        },
        tapping: {
            x: 4,
            opacity: 0.6,
            transition: { opacity: { duration: 0.1 } }
        }
    };

    return (
        <>
            <Link
                href={
                    'https://chrome.google.com/webstore/detail/tabextend/ffikidnnejmibopbgbelephlpigeniph'
                }
            >
                <motion.button
                    animate="notHovered"
                    initial="notHovered"
                    whileHover="hover"
                    whileTap="tapping"
                    variants={parentAnimation}
                    className="te-primary-button header-cta-button"
                    willchange="transform"
                    //whileHover={{ filter: "var(--te-hover-brightness)" }}
                    //whileTap={{ scale: 0.9875, transition: { type: "spring" } }}
                >
                    <div style={{ display: 'flex' }}>
                        <CTAarrowIcon
                            w="32px"
                            arrowAnimation={arrowAnimation}
                        />
                        <motion.span
                            variants={textAnimation}
                            style={{ marginRight: 'auto' }}
                        >
                            Add to chrome
                        </motion.span>
                    </div>
                </motion.button>
            </Link>
            {/* <p
        style={{
          fontSize: "13px",
          color: "var(--hero-subtext)",
          marginTop: "1.6rem",
          opacity: 0.8,
          //filter: "brightness(1.28)",
        }}
      >
        Try with one-click install. No sign-up. Free forever.
      </p> */}
        </>
    );
};

const CTAarrowIcon = ({
    w,
    arrowAnimation,
    margin = 'auto 12px auto auto'
}) => {
    return (
        <motion.svg
            variants={arrowAnimation}
            viewBox="0 0 24 24"
            style={{
                width: w,
                margin: margin,
                padding: '2px',
                borderRadius: '50%',
                boxShadow: 'rgb(255 255 255 / 11%) 0 0px 8px -1px'
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
        >
            <path
                opacity="0.4"
                d="M3.33789 7C5.06694 4.01099 8.29866 2 12.0001 2C17.5229 2 22.0001 6.47715 22.0001 12C22.0001 17.5228 17.5229 22 12.0001 22C8.29866 22 5.06694 19.989 3.33789 17"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M12 16L16 12M16 12L12 8M16 12H2"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </motion.svg>
    );
};
