import React from "react";
import CenteredColumns from "components/CenteredColumns";
import {
  CollectionIcon,
  ExternalIcon,
  GlobeIcon,
  LinkIcon,
  SettingsIcon,
  MouseClick,
  AddNote,
  CopyClipboard,
  MobileIcon,
  ShareCloud,
  SearchIcon,
  AlarmIcon,
} from "components/IconBlocks";

function Features2(props) {
  const iconSize = "52px";
  return (
    <CenteredColumns>
      {props.items.map((item, index) => (
        <div
          className="column is-one-third has-text-centered"
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            paddingLeft: "3rem",
            paddingRight: "3rem",
          }}
          key={index}
        >
          <div className="Features2__feature">
            {/* <div
              className={`Features2__icon icon is-size-1`}
              style={{height: "74px", width: "78px", borderRadius: "14px", background: "#F8F8FA", boxShadow: "0 24px 34px -6px rgba(194,200,206,0.80), inset 0 0 6px 0 #E5EAF3, inset -2px -2px 1px 0 #D5DAE5, inset 2px 2px 3px 0 rgba(255,255,255,0.17)"}}
            >
              <img src={item.icon} alt={item.title}></img>
            </div> */}
            <div
              style={{
                height: "58px",
                width: "58px",
                background: "#F4F5F7",
                backgroundImage: "linear-gradient(180deg, #F4F5F7 0%, #ECEDF2 100%)",
                boxShadow:
                  "0 1px 3px 0 rgba(103,109,132,0.30), inset 1px 1px 1px 0 rgba(255,255,255,0.95), inset -1px -1px 1px 0 rgb(192 194 197)",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                margin: "auto",
                marginBottom: "2.4rem",
                opacity: item.coming ? "0.50" : "1",
              }}
            >
              {item.icon === "external" ? (
                <ExternalIcon IconColor={"rgb(0, 120, 255)"} height={"40px"} width={"36px"} />
              ) : item.icon === "SearchIcon" ? (
                <SearchIcon IconColor={"rgb(0, 120, 255)"} height={"40px"} width={"36px"} />
              ) : item.icon === "ShareCloud" ? (
                <ShareCloud IconColor={"rgb(0, 120, 255)"} height={"40px"} width={"36px"} />
              ) : item.icon === "MobileIcon" ? (
                <MobileIcon IconColor={"rgb(0, 120, 255)"} height={"40px"} width={"36px"} />
              ) : item.icon === "CopyClipboard" ? (
                <CopyClipboard IconColor={"rgb(0, 120, 255)"} height={"40px"} width={"36px"} />
              ) : item.icon === "AlarmIcon" ? (
                <AlarmIcon IconColor={"rgb(0, 120, 255)"} height={"40px"} width={"36px"} />
              ) : item.icon === "AddNote" ? (
                <AddNote IconColor={"rgb(0, 120, 255)"} height={"40px"} width={"36px"} />
              ) : item.icon === "MouseClick" ? (
                <MouseClick IconColor={"rgb(0, 120, 255)"} height={"40px"} width={"36px"} />
              ) : item.icon === "SettingsIcon" ? (
                <SettingsIcon IconColor={"rgb(0, 120, 255)"} height={"40px"} width={"36px"} />
              ) : item.icon === "GlobeIcon" ? (
                <GlobeIcon IconColor={"rgb(0, 120, 255)"} height={"40px"} width={"36px"} />
              ) : item.icon === "LinkIcon" ? (
                <LinkIcon IconColor={"rgb(0, 120, 255)"} height={"40px"} width={"36px"} />
              ) : (
                ""
              )}
            </div>
            <h1
              className="title is-4 is-spaced"
              style={{ fontWeight: "700", fontSize: "1.275rem" }}
            >
              {item.title}
            </h1>
            <p
              className="subtitle is-6"
              style={{
                fontFamily: "Rubik, sans-serif",
                color: "rgb(74, 84, 94)",
                fontSize: "1rem",
                lineHeight: "1.5",
              }}
            >
              {item.subtitle}
            </p>
            {item.coming && (
              <span
                className="tag is-primary is-medium"
                style={{
                  backgroundColor: "#E4E6E8",
                  fontWeight: "500",
                  marginLeft: "4px",
                  paddingRight: "8px",
                  paddingLeft: "8px",
                  color: "#05184a",
                  marginTop: "-8px",
                }}
              >
                Coming soon
              </span>
            )}
          </div>
        </div>
      ))}
    </CenteredColumns>
  );
}

export default Features2;
