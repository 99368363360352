import React from "react";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Clients from "components/Clients";

function ClientsSection(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <header className="has-text-centered mb-5">
          <p className="subtitle">
            <span
              className="SectionHeader__no-classname"
              style={{
                fontWeight: "700",
                fontSize: "14px",
                letterSpacing: "1.36px",
                color: "#687482",
              }}
            >
              {props.subtitle}
            </span>
          </p>
        </header>
        <Clients
          items={[
            {
              name: "Chrome",
              image: "/browsers/Chrome_logo.png",
              width: "100",
            },
            {
              name: "Edge",
              image: "/browsers/Edge_logo.png",
              width: "100",
            },
            {
              name: "Opera",
              image: "/browsers/Opera_logo.png",
              width: "100",
            },
            {
              name: "Safari",
              image: "/browsers/Safari_logo.png",
              width: "100",
            },
            {
              name: "Firefox",
              image: "/browsers/Firefox_logo.png",
              width: "100",
            },
          ]}
        ></Clients>
      </div>
    </Section>
  );
}

export default ClientsSection;
