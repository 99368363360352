import React, { useEffect } from "react";
import Image from "next/image";

function Features(props) {
  return (
    <div className="Features">
      {props.items.map((item, index) => (
        <div
          className="Features__columns columns is-variable is-8 is-vcentered has-text-centered-mobile"
          key={index}
        >
          <div className="column is-half">
            <div className="is-inline-block-tablet">
              {/* <FeatureCircle icon={item.icon} name={item.iconname} /> */}
            </div>
            <h3 className="Features__title title has-text-weight-bold is-spaced is-3">
              {item.title}
            </h3>
            <p
              className="subtitle"
              style={{
                fontFamily: "Rubik, sans-serif",
                color: "#687482",
                fontSize: props.size === 1 ? "1.375rem" : "1.175rem",
                lineHeight: "1.5",
                paddingBottom: "2rem",
              }}
            >
              {item.description}
            </p>
          </div>
          <div className="column">
            <figure
              className="Features__image image"
              style={{ maxWidth: "640px" }}
            >
              <div
                style={{
                  /* paddingTop: "24px", paddingLeft: "24px", paddingRight: "24px", */ overflow:
                    "hidden",
                  borderRadius: "18px",
                  boxShadow:
                    "0 2px 8px 0 rgba(205,205,205,0.80), 0 15px 30px -12px rgba(155,168,180,0.50)",
                  display: "inline-block",
                }}
              >
                <Image
                  src={item.image}
                  alt={item.title}
                  width={640}
                  height={377}
                  quality={100} /* style={{maxWidth: "600px"}} */
                ></Image>
              </div>
            </figure>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Features;
