import React from "react";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import SectionButton from "components/SectionButton";

function CtaSection(props) {
  return (
      <section className="SectionComponent hero section is-block is-relative" style={{background: "#0078FF"}}>
      <div className="container has-text-centered">
        <div className="is-vcentered is-centered is-variable is-8 is-multiline py-3">
          <h1 className="title is-spaced" style={{color: "#FFF", marginBottom: "2.5rem"}}>{props.title}</h1>
          <button
    style={{cursor: "cursor", color: "#FFF", border: "none", backgroundColor: "#0078FF", borderRadius: "12px", boxShadow: " 8px 6px 28px 0 rgba(4,53,108,0.75), -8px -6px 28px 0 rgba(144,187,247,0.20), inset 3px 3px 6px 0 rgba(180,214,253,0.20), inset -4px -4px 6px 0 rgba(3,96,200,0.35)", minHeight: "84px", fontSize: "24px", minWidth: "400px"}}
    >
        <b>Try for free</b>
        , Add to browser
    </button>
        </div>
      </div>
      </section>
  );
}

export default CtaSection;
